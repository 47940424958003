.is-error-button {
    background-color: #e76e55;
    color: white;
}

.is-error-button:active {
    color: #e76e55;
    background-color: rgba(239, 239, 239);
  }

.is-primary-button {
    background-color: #209cee;
    color: white;
}

.score-label {
    font-weight: bold !important;
    font-size: 16px;
}