.content {
  padding: 0 1rem;
  margin: 0 1rem;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
  font-weight: bold;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  /* body { */
  /* } */
}

.container-items > *{
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.tab-button {
  /* border-width: 4px; */
  /* border-color: #212529; */
  margin-bottom: 0px;
}

.airtable {
  margin-top: 0;
}

.inactive {
  color: grey;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
