.slider-div {
    /* vertical-align: middle; */
    padding: .5rem .5rem .5rem 1rem;
}
.filter {
    /* margin-left: 10px; */
    /* align-content: center; */
}

.filter-bar {
    margin-left: 0px;
}

.nes-btn:focus {
    outline: none;
}